;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"bkVuNCSrJ9kEpF9O6DLzE"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'
import config from './config'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: config.sentry.dns,
    environment: config.environment,
    release: config.sentry.release,
    tracesSampleRate: config.sentry.tracesSampleRate || 0.1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
  })
}

export const GIGYA_SCRIPT_ID = 'gigya'
export const TWITTER_SCRIPT_ID = 'twitter-wjs'
export const TAGCOMMANDER_SCRIPT_ID = 'tagcommander'
export const GOOGLE_AD_MANAGER_SCRIPT_ID = 'gam'
export const FACEBOOK_SCRIPT_ID = 'fb-root'
export const OUTBRAIN_SCRIPT_ID = 'outbrain'
export const OWNPAGE_SCRIPT_ID = 'ownpage'
export const HUBVISOR_SCRIPT_ID = 'hubvisor'
export const INSTAGRAM_SCRIPT_ID = 'instagram'
export const VIDEO_IFRAME_INTEGRATION_ID = 'video-embed'
export const PYM_SCRIPT_ID = 'pym'
export const TEADS_SCRIPT_ID = 'teads'
export const PUBSTACK_SCRIPT_ID = 'pubstack'
export const BEOP_SCRIPT_ID = 'beop'
export const FACILITI_SCRIPT_ID = 'faciliti'
export const POOOL_ACCESS_SCRIPT_ID = 'poool-access'
export const POOOL_AUDIT_SCRIPT_ID = 'poool-audit'
export const POOOL_ENGAGE_SCRIPT_ID = 'poool-engage'
export const NONLI_SCRIPT_ID = 'nonli'
export const OPTA_SCRIPT_ID = 'opta'

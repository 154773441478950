import { theme } from './theme'

export const mixins = {
  fonts: {
    item: {
      fontFamily: 'var(--font-overpass)',
      fontStyle: 'normal',
      fontWeight: '900',
      fontSize: '14px',
      lineHeight: '146%',
    },
    base: {
      large: {
        fontFamily: 'var(--font-overpass)',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '18px',
        lineHeight: '144%',
      },
      medium: {
        fontFamily: 'var(--font-overpass)',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '15px',
        lineHeight: '146%',
      },
      small: {
        fontFamily: 'var(--font-overpass)',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '145%',
      },
      xsmall: {
        fontFamily: 'var(--font-overpass)',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '11px',
        lineHeight: '100%',
      },
    },
    article: {
      interTitle: {
        fontFamily: 'var(--font-overpass)',
        fontSize: '30px',
        fontWeight: '300',
        lineHeight: '120%',
      },
      h2: {
        fontFamily: 'var(--font-overpass)',
        fontStyle: 'normal',
        fontWeight: '800',
        fontSize: '28px',
        lineHeight: '109%',
      },
      h3: {
        fontFamily: 'var(--font-overpass)',
        fontStyle: 'normal',
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '133%',
      },
      h5: {
        fontFamily: 'var(--font-overpass)',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '11px',
        lineHeight: '100%',
      },
      h6: {
        fontFamily: 'var(--font-overpass)',
        fontStyle: 'normal',
        fontWeight: '800',
        fontSize: '15px',
        lineHeight: '146%',
      },
      quote: {
        fontFamily: 'var(--font-overpass)',
        fontStyle: 'italic',
        fontWeight: '700',
        fontSize: '32px',
        lineHeight: '120%',
      },
    },
  },
  mediaQuery: {
    mobile: `(min-width: ${theme.breakpoints.sm}px)`,
    onlyMobile: `(min-width: ${theme.breakpoints.sm}px) and (max-width: ${
      theme.breakpoints.md - 1
    }px)`,
    onlyPortrait: `(orientation:portrait)`,
    tablet: `(min-width: ${theme.breakpoints.md}px)`,
    onlyTablet: `(min-width: ${theme.breakpoints.md}px) and (max-width: ${
      theme.breakpoints.lg - 1
    }px)`,
    tabletPaysage: `(min-width: ${theme.breakpoints.lg}px)`,
    onlyTabletPaysage: `(min-width: ${theme.breakpoints.lg}px) and (max-width: ${
      theme.breakpoints.xl - 1
    }px)`,
    desktop: `(min-width: ${theme.breakpoints.xl}px)`,
    maxWidth: `(min-width: ${theme.layout.maxWidth}px)`,
  },
  header: {
    maxWidthDesktop: `
        margin: auto;
        padding: 0  ${theme.block.marginLR}px;
        max-width: ${theme.layout.maxWidth}px;
      `,
  },
} as const

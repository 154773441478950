import type { GigyaResponse } from '../types/gigya'
import type { User } from '../types/user'

export async function setAccountInfo(props: Partial<User>): Promise<GigyaResponse> {
  if (!window?.gigya) {
    // eslint-disable-next-line
    // @ts-ignore
    return { status: 'NOTOK', errorCode: 500 }
  }

  const response = await new Promise(
    (resolve: (value: GigyaResponse | PromiseLike<GigyaResponse>) => void) => {
      window.gigya.accounts.setAccountInfo({
        callback: resolve,
        ...props,
      })
    },
  )

  return response
}

export async function getAccountInfo(params: { include?: string }): Promise<GigyaResponse> {
  if (!window?.gigya) {
    // eslint-disable-next-line
    // @ts-ignore
    return { status: 'NOTOK', errorCode: 500 }
  }

  const response = await new Promise(
    (resolve: (value: GigyaResponse | PromiseLike<GigyaResponse>) => void) => {
      window?.gigya?.accounts.getAccountInfo({
        callback: resolve,
        ...params,
      })
    },
  )

  return response
}

export async function getGigyaJWT(): Promise<string> {
  if (!window?.gigya) {
    return ''
  }

  const response = await new Promise(
    (resolve: (value: GigyaResponse | PromiseLike<GigyaResponse>) => void) => {
      window.gigya.accounts.getJWT({
        callback: resolve,
      })
    },
  )

  return response?.id_token || ''
}

export function getFirstName(user: User): string {
  if (!user?.profile?.firstName) {
    return ''
  }

  return user.profile.firstName.charAt(0).toUpperCase() + user.profile.firstName.slice(1)
}

export function hasFavoriteCategories(user: User): boolean {
  return !!(
    user?.data?.Tf1Info?.favoriteCategories || user?.data?.Tf1Info?.favoriteCategories?.length
  )
}

import type { City, Country, DefinedTerm, Person } from 'schema-dts'
import config from '../../config'

export interface IPersonData {
  firstname?: string
  lastname?: string
  image?: string
  description?: string
  url?: string
  name?: string
  knowsAbout?: string[]
  jobTitle?: string | DefinedTerm
  givenName?: string
  familyName?: string
  knowsLanguage?: string
  birthDate?: string
  birthPlace?: string
  deathDate?: string
  deathPlace?: string
  nationality?: string
  workLocation?: string
  socials?: string[]
  collegeOrUniversity?: {
    name?: string
    sameAs?: string
    startDate?: string
  }
}

export function journalistPersonObject(data: IPersonData): Person
export function journalistPersonObject(data: IPersonData[]): Person[]
export function journalistPersonObject(data: unknown): unknown {
  if (Array.isArray(data)) {
    const personData = data as IPersonData[]
    return personData.map((data) =>
      personObject({
        ...data,
        knowsLanguage: 'fr',
        jobTitle: {
          '@type': 'DefinedTerm',
          inDefinedTermSet:
            'https://www.francetravail.fr/employeur/vos-recrutements/le-rome-et-les-fiches-metiers.html',
          termCode: 'ROME : E1106',
          name: 'Journaliste',
          url: 'https://candidat.francetravail.fr/metierscope/fiche-metier/E1106/journaliste',
        },
      }),
    )
  }
  const personData = personObject(data as IPersonData)
  return {
    ...(personData as object),
    knowsLanguage: 'fr',
    jobTitle: {
      '@type': 'DefinedTerm',
      inDefinedTermSet:
        'https://www.francetravail.fr/employeur/vos-recrutements/le-rome-et-les-fiches-metiers.html',
      termCode: 'ROME : E1106',
      name: 'Journaliste',
      url: 'https://candidat.francetravail.fr/metierscope/fiche-metier/E1106/journaliste',
    },
  }
}

export function person(personData: IPersonData): Person | null {
  if (!personData) {
    return {
      '@type': 'Person',
      name: 'La rédaction TF1 Info',
    }
  }

  const url = personData?.url
    ? /^http[s]?:\/\//.test(personData.url)
      ? personData.url
      : `${config.baseUrl}${personData.url[0] !== '/' ? '/' : ''}${personData.url}`
    : ''

  const givenName = personData.givenName || personData?.firstname?.trim()
  const familyName = personData?.familyName || personData?.lastname?.trim()
  const name = givenName && familyName ? `${givenName} ${familyName}` : personData?.name
  const image = personData?.image
  const description = personData?.description
  const jobTitle: DefinedTerm | undefined = personData?.jobTitle
    ? typeof personData.jobTitle === 'string'
      ? { '@type': 'DefinedTerm', name: personData.jobTitle }
      : personData.jobTitle
    : undefined
  const knowsAbout = personData.knowsAbout
  const knowsLanguage = personData?.knowsLanguage
  const birthDate = personData?.birthDate
  const deathDate = personData?.deathDate
  const birthPlace: City | undefined = personData?.birthPlace
    ? { '@type': 'City', name: personData.birthPlace }
    : undefined
  const deathPlace: City | undefined = personData?.deathPlace
    ? { '@type': 'City', name: personData.deathPlace }
    : undefined
  const nationality: Country | undefined = personData?.nationality
    ? { '@type': 'Country', name: personData.nationality }
    : undefined
  const workLocation: Country | undefined = personData?.workLocation
    ? { '@type': 'Country', name: personData.workLocation }
    : undefined
  const socials = personData?.socials
  const collegeOrUniversity = personData?.collegeOrUniversity

  return {
    '@type': 'Person',
    ...(givenName ? { givenName } : {}),
    ...(familyName ? { familyName } : {}),
    ...(name ? { name } : {}),
    ...(image ? { image } : {}),
    ...(url ? { url } : {}),
    ...(description ? { description } : {}),
    ...(knowsAbout ? { knowsAbout } : {}),
    ...(knowsLanguage ? { knowsLanguage } : {}),
    ...(jobTitle ? { jobTitle } : {}),
    ...(birthDate ? { birthDate } : {}),
    ...(birthPlace ? { birthPlace } : {}),
    ...(deathDate ? { deathDate } : {}),
    ...(deathPlace ? { deathPlace } : {}),
    ...(nationality ? { nationality } : {}),
    ...(workLocation ? { workLocation } : {}),
    ...(socials ? { sameAs: socials } : {}),
    ...(collegeOrUniversity
      ? {
          alumniOf: {
            '@type': 'OrganizationRole',
            alumniOf: {
              '@type': 'CollegeOrUniversity',
              name: collegeOrUniversity.name,
              sameAs: collegeOrUniversity.sameAs,
            },
            startDate: collegeOrUniversity.startDate,
          },
        }
      : {}),
  }
}

export default function personObject(data: IPersonData): Person
export default function personObject(data: IPersonData[]): Person[]
export default function personObject(data: unknown): unknown {
  return Array.isArray(data) ? data.map((author) => person(author)) : person(data)
}
